import {createRouter, createWebHistory} from "vue-router";

const router = createRouter({
    history: createWebHistory(),
    routes: [
        //  For client View ************
        {
            path: "/",
            name: "home",
            component: () => import("@/pages/client/HomeView.vue"),
            meta: {
                authorMiddleware: false,
                idx: 0
            },
        },
        {
            path: "/about",
            name: "about",
            component: () => import("@/pages/client/AboutVue.vue"),
            meta: {
              authorMiddleware: false,
              idx: 1
            },
        },
        {
            path: "/images",
            name: "images",
            component: () => import("@/pages/client/ImageView.vue"),
            meta: {
              authorMiddleware: false,
              idx: 2
            },
        },
        {
            path: "/videos",
            name: "videos",
            component: () => import("@/pages/client/VideoView.vue"),
            meta: {
              authorMiddleware: false,
              idx: 3
            },
        },
        {
            path: "/documents",
            name: "document",
            component: () => import("@/pages/client/DocumentView.vue"),
            meta: {
              authorMiddleware: false,
              idx: 4
            },
        },
        // For admin view ***************
        {
            path: "/admin",
            name: "dashboard",
            component: () => import("@/pages/client/DocumentView.vue"),
            // beforeEnter: requireAuthor,
            meta: {
                authorMiddleware: true,
                idx: 5
            },
        },
    ],
    scrollBehavior() {
        // always scroll to top
        return {top: 0}
    },
});

router.beforeResolve(async (to, from, next) => {
    // Checking animation when change router
    const toDepth = to.meta.idx;
    const fromDepth = from.meta.idx ?? -1;
    to.meta.transition =
        toDepth < fromDepth ? "slide-fade-right" : "slide-fade-left";

    // Checking author




    next();
});

export default router;
