<template>
  <!-- Navbar start -->
  <nav
    class="w-screen fixed bg-opacity-90 bg-white p-4 lg:px-20 flex justify-between z-50 fixed top-0"
  >
    <!-- Logo start -->
    <div class="h-full flex flex-col justify-center">
      <p class="tracking-widest font-semibold">CA ĐOÀN FATIMA</p>
      <p class="tracking-widest font-light">Giáo xứ Phùng Khoang</p>
    </div>
    <!-- Logo end -->

    <!-- Toggler start -->
    <button role="menubar" class="md:hidden" @click="navToggle()">
      <i class="fa-solid fa-bars text-2xl"></i>
    </button>
    <!-- Toggler end -->

    <!-- Menu content start -->
    <div
      class="absolute left-0 right-0 translate-y-16 bg-opacity-90 bg-white md:bg-none md:bg-opacity-0 shadow hidden md:flex flex-col gap-4 items-center p-4 md:flex-row md:static md:shadow-none md:translate-y-0"
      role="menu"
      aria-expanded="false"
    >
      <!-- Menu List -->
      <router-link
          v-for="(menu, idxMenu) in menuData"
          :key="`menu#${idxMenu}`"

          :to="menu.path"
        ><a href="#" role="menuitem">
          <p class="mx-4" :class="activeRouter == menu.path ? 'underline' : ''">
            {{ menu.name }}
          </p>
        </a></router-link
      >

      <!-- Social Link -->
      <a href="#" role="menuitem" class="social-network">
        <img
          class="mx-4"
          :src="require('@/assets/icons/facebook.svg')"
          alt="Facebook"
          @click="clickSocial('facebook')"
        />
      </a>

      <a href="#" role="menuitem" class="social-network">
        <img
          :src="require('@/assets/icons/youtube.svg')"
          alt="Youtube"
          @click="clickSocial('youtube')"
        />
      </a>

      <!-- Links end -->
    </div>
    <!-- Menu content end -->
  </nav>
  <!-- Navbar end -->
</template>

<script>
export default {
  data(){
    return{
      menuData:[
        {
          path: "/",
          name: "Trang Chủ",
        },
        {
          path: "/about",
          name: "Thông Tin",
        },
        {
          path: "/images",
          name: "Hình Ảnh",
        },
        {
          path: "/videos",
          name: "Videos",
        },
        {
          path: "/document",
          name: "Tài Liệu",
        }
      ]
    }
  },
  computed: {
    activeRouter() {
      return this.$route.path;
    },
  },
  methods: {
    // Toogle navbar mode
    navToggle() {
      let menu = document.querySelector("[role='menu']");
      let isExpanded = menu.getAttribute("aria-expanded");
      menu.setAttribute("aria-expanded", !isExpanded);
      menu.classList.toggle("hidden");
      menu.classList.toggle("flex");
    },
    clickSocial(flag) {
      let _link =
        flag == "facebook"
          ? "https://www.facebook.com/profile.php?id=100094592153024"
          : "https://www.youtube.com/@FatimaCaoan-mx2sb";

      window.open(_link, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.social-network {
  img {
    height: 30px;
  }
}
</style>