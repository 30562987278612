import {createApp} from 'vue'
import App from './App.vue'
import router from './system/router/router'
import http from './system/http/http'
import VueSplide from "@splidejs/vue-splide";
import '@splidejs/vue-splide/css';

import '@/style.css'


createApp(App)
    .use(VueSplide)
    .use(http)
    .use(router)
    .mount('#app')
