const dashboardMenu = [
    {
        id: 0,
        name: "DashBoard",
        path:"/"
    },
    {
        id: 1,
        name: "Images",
        path:"/images"
    },
    {
        id: 2,
        name: "Videos",
        path:"/videos"
    },{
        id: 3,
        name: "Documents",
        path:"/documents"
    }
]
export  default  dashboardMenu;