<template>
  <admin-view v-if="isAdmin"/>
  <client-view v-else/>
</template>

<script>
import ClientView from "@/pages/client/ClientView.vue";
import AdminView from "@/pages/admin/AdminView.vue";
export default {
  components:{
    ClientView,
    AdminView
  },
  data(){
    return {
      isAdmin: false
    }
  },

}
</script>

<style lang="scss" scoped>

</style>
