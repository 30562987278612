<template>
  <div class="admin-component w-screen h-screen flex flex-row gap-10">
    <left-navbar-component></left-navbar-component>
    <!-- Router view start -->
    <router-view v-slot="{ Component, route }">
      <Transition :name="route.meta.transition" mode="out-in">
        <div :key="route.name" class="w-full h-full p-8 bg-yellow-300">
          <component :is="Component"></component>
        </div>
      </Transition>
    </router-view>
  </div>

</template>

<script>

import FooterElement from "@/components/common/FooterElement.vue";
import NavbarElement from "@/components/common/NavbarElement.vue";
import LeftNavbarComponent from "@/components/admin/LeftNavbarComponent.vue";

export default {
  components: {LeftNavbarComponent, NavbarElement, FooterElement}

}
</script>

<style scoped lang="scss">
.slide-fade-left-enter-active {
  transition: all 0.7s ease-out;
}
.slide-fade-left-enter-from,
.slide-fade-left-leave-to {
  transform: translateX(4em);
  opacity: 0;
}

.slide-fade-right-enter-active {
  transition: all 0.7s ease-out;
}
.slide-fade-right-enter-from,
.slide-fade-right-leave-to {
  transform: translateX(-4em);
  opacity: 0;
}
</style>