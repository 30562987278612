<template>
  <!-- Footer start -->
  <div class="bg-stone-700 w-full flex flex-col md:flex-row justify-center items-center md:justify-evenly gap-8 p-8">
    <!-- Overview start -->
    <div class="text-stone-100 text-center">
      <p class="tracking-widest font-semibold text-stone-100">CA ĐOÀN FATIMA</p>
      <p class="tracking-widest font-light text-stone-100">GIÁO XỨ PHÙNG KHOANG</p>
    </div>
    <!-- Overview end -->

    <!-- Links start -->
    <div class="text-stone-100 flex flex-col md:flex-row justify-center items-center gap-2 md:gap-8 lg:gap-20">
      <a href="#">
        <i class="fa fa-mobile socialIcon"></i>
        091 939 38 63
      </a>
      <a href="#">
        <i class="fa fa-envelope-o socialIcon"></i>
        Cadoanfatimapk2023@gmail.com
      </a>
    </div>
    <!-- Links end -->
  </div>
  <!-- Footer end -->
</template>

<script>
export default {}
</script>

<style>

</style>