<template>
  <div class="left-nav-bar-component min-h-screen flex flex-col flex-auto flex-shrink-0 antialiased bg-gray-50 text-gray-800">
    <div class="fixed flex flex-col top-0 left-0 w-64 bg-white h-full border-r">
      <div class="flex items-center justify-center h-14 border-b">
        <div>Ca Doan Fatima</div>
      </div>
      <div class="overflow-y-auto overflow-x-hidden flex-grow">
        <ul class="flex flex-col py-4 space-y-1">
          <li v-for="(menu, menuIdx) in menuList" :key="menuIdx+'_menu'">
            <a href="#" class="relative flex flex-row items-center h-11 focus:outline-none hover:bg-gray-50 text-gray-600 hover:text-gray-800 border-l-4 border-transparent hover:border-yellow-400 pr-6">
            <span class="inline-flex justify-center items-center ml-4">
              <svg class="w-5 h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path></svg>
            </span>
              <span class="ml-2 text-sm tracking-wide truncate">{{menu.name}}</span>
            </a>
          </li>
        </ul>
      </div>


    </div>
  </div>
</template>
<script>
import dashboardMenu from "@/components/admin/dashboardMenu";
export default {
  data(){
    return{
      menuList: dashboardMenu,
    }
  }

}
</script>
<style lang="scss" scoped>
.left-nav-bar-component{
  width: 200px;
  background: red;
}
</style>